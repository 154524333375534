/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/media-has-caption */
import { FC, memo, useMemo, useRef, useEffect } from 'react';
import cx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { useMedia, useTranslation as t } from '@utils/hooks';
import * as styles from '../styles';
import extraStyles from '../extraStyles.module.css';

type UploadedVideo = {
  video: { mp4Url: string; thumbnailUrl: string; duration: number };
  title: string;
};

type YoutubeVideo = {
  videoId: string;
  title?: string;
  duration?: number;
};

type Video = YoutubeVideo | UploadedVideo;

interface Props {
  videos: Video[];
  switchHandler: (index: number, item: HTMLVideoElement) => void;
  videoEl: HTMLVideoElement;
  activeIndex: number;
}

const ResizePlugin = (slider): void => {
  const observer = new ResizeObserver(() => {
    slider.update();
  });

  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

const VideoButtons: FC<Props> = ({
  videos,
  switchHandler,
  activeIndex,
  videoEl,
}) => {
  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const SELECT_VIDEO = t('select_video_label');
  const YOUTUBE_THUMBNAIL_URL = 'https://i.ytimg.com/vi/';
  const NOW_PLAYING = t('label_now_playing');

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const videoAmnt = Array.isArray(videos) && videos?.length;

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, videos?.length);
  }, [videos]);

  const [sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      slides: {
        perView: 'auto',
        spacing: 0,
        origin: 'auto',
      },
      mode: 'free-snap',
      loop: false,
      initial: 0,
    },
    [ResizePlugin]
  );

  const composedClassNameSlideWrapper = useMemo(
    () =>
      cx(
        styles.videoThumbnailSection(videoAmnt > 3),
        extraStyles.thumbnailSection,
        'keen-slider',
        'space-x-4'
      ),
    [videoAmnt]
  );

  const composedClassNameWrapper = useMemo(
    () =>
      cx(
        styles.videoThumbnailSection(videoAmnt > 3),
        styles.verticalContainer,
        'space-y-4'
      ),
    [videoAmnt]
  );

  const handleSwitch = (i, elem, video): void => {
    if (elem) switchHandler(i, elem);
    else switchHandler(i, video.videoId);
  };

  return isSm || isMd ? (
    <div id="slider" ref={sliderRef} className={composedClassNameSlideWrapper}>
      {videos?.map((video, i) => {
        const backgroundImage =
          'video' in video && video?.video?.thumbnailUrl
            ? `url(${video?.video?.thumbnailUrl})`
            : 'videoId' in video
            ? `url(${YOUTUBE_THUMBNAIL_URL}${video?.videoId}/mqdefault.jpg)`
            : null;
        return (
          <div
            className="keen-slider__slide"
            key={
              'videoId' in video ? video?.videoId : video?.video?.thumbnailUrl
            }
            style={{ minWidth: '260px', maxWidth: '260px' }}
          >
            <button
              className={cx(styles.thumbContainer)}
              onClick={() => handleSwitch(i, videoEl, video)}
              type="button"
              key={
                'videoId' in video ? video?.videoId : video?.video?.thumbnailUrl
              }
              aria-label={`${SELECT_VIDEO}: ${video?.title}`}
              style={{
                backgroundImage,
                height: '146px',
                width: '260px',
              }}
            >
              <div
                className={styles.buttonOverlay(activeIndex === i)}
                style={{ height: '146px', width: '260px' }}
              >
                <div className={styles.thumbnailTextContainer}>
                  <div className={styles.thumbnailTitle}>
                    {activeIndex === i ? (
                      <div className={styles.nowPlaying}>
                        <svg
                          viewBox="0 0 12 12"
                          className={styles.nowPlayingIcon}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.6215 4.61519C11.6912 5.23045 11.6912 6.77381 10.6215 7.38907L3.38994 11.5486C2.32327 12.1621 0.992187 11.3922 0.992188 10.1616L0.992188 1.84261C0.992188 0.612085 2.32327 -0.157856 3.38994 0.455675L10.6215 4.61519Z"
                            fill="white"
                          />
                        </svg>
                        <span>{NOW_PLAYING}</span>
                      </div>
                    ) : 'title' in video ? (
                      video?.title
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </button>
          </div>
        );
      })}
    </div>
  ) : (
    <div className={composedClassNameWrapper}>
      {videos?.map((video, i) => {
        const backgroundImage =
          'video' in video && video?.video?.thumbnailUrl
            ? `url(${video?.video?.thumbnailUrl})`
            : 'videoId' in video
            ? `url(${YOUTUBE_THUMBNAIL_URL}${video?.videoId}/mqdefault.jpg)`
            : null;

        return (
          <button
            className={styles.thumbContainer}
            onClick={() => handleSwitch(i, videoEl, video)}
            type="button"
            key={
              'videoId' in video ? video?.videoId : video?.video?.thumbnailUrl
            }
            aria-label={`${SELECT_VIDEO}: ${video?.title}`}
            style={{
              backgroundImage,
              height: 'auto',
              width: '100%',
              aspectRatio: '16/9',
            }}
          >
            <div
              className={styles.buttonOverlay(activeIndex === i)}
              style={{ height: '100%', width: '100%' }}
            >
              <div className={styles.thumbnailTextContainer}>
                <div className={styles.thumbnailTitle}>
                  {activeIndex === i ? (
                    <div className={styles.nowPlaying}>
                      <svg
                        viewBox="0 0 12 12"
                        className={styles.nowPlayingIcon}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.6215 4.61519C11.6912 5.23045 11.6912 6.77381 10.6215 7.38907L3.38994 11.5486C2.32327 12.1621 0.992187 11.3922 0.992188 10.1616L0.992188 1.84261C0.992188 0.612085 2.32327 -0.157856 3.38994 0.455675L10.6215 4.61519Z"
                          fill="white"
                        />
                      </svg>
                      <span>{NOW_PLAYING}</span>
                    </div>
                  ) : 'title' in video ? (
                    video?.title
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default memo(VideoButtons);
